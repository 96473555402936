import Link from 'next/link';
import React from 'react';
import { baseUrl } from '../../../config/apiConfig';
import Image from "next/image";

const NotFound = ({ message, link = null, linkText = "" }) => (
  <div className="empty-content-box">
    <div className="empty-content-box-icon position-relative mb-5">
      <Image
        loading="lazy"
        src={baseUrl + "/images/404.svg"}
        alt=""
        fill
        sizes="100vw" />
    </div>
    <div className="not-found-title">
      <h2><span>Opps!</span> Sorry</h2>
      <p>{message}</p>
    </div>
    {link &&
      <Link href={link} className="btn btn-sm btn-dark">
        {linkText}
      </Link>
    }
  </div>
)


export default NotFound;
import React from 'react';
import { baseUrl } from '../../../config/apiConfig';

const SectionTitle = ({
  title = '',
  subTitle = '',
  link = null,
  lineCurve = false,
  lineCurveSize = '',
  subTitlePosition = 'top',
}) => {
  return (
    <div
      className={`section-title-area d-flex flex-column align-items-center justify-content-center  mb-4 position-relative`}
    >
      {lineCurve && (
        <div
          className={`title-line-curve position-absolute top-50 start-50 translate-middle ${lineCurveSize}`}
        >
          <img src={baseUrl + '/images/curve-line.png'} alt="" />
        </div>
      )}
      {subTitle && subTitlePosition == 'top' && (
        <h3
          className="fs-32 lh-44 fw-normal text-uppercase mb-0"
          dangerouslySetInnerHTML={{ __html: subTitle }}
        ></h3>
      )}
      {title && (
        <h2
          className="fs-48 lh-56 text-gradient text-gradient-blue-light-blue mb-0"
          dangerouslySetInnerHTML={{ __html: title }}
        ></h2>
      )}
      {subTitle && subTitlePosition == 'bottom' && (
        <h3
          className="fs-24 lh-36 fw-normal text-capitalize mb-0 mt-4  text-justify"
          dangerouslySetInnerHTML={{ __html: subTitle }}
        ></h3>
      )}
    </div>
  );
};

export default SectionTitle;

import React, { useState, useEffect } from 'react';
import { apiUrl } from '../../../config/apiConfig';
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper';
import SectionTitle from '../section/SectionTitle';
import BlogBox from './BlogBox';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const RealatedBlogArea = ({ relatedBlogs }) => {

  return (
    <>
      {relatedBlogs && relatedBlogs.length > 0 && (
        <div className="blog-area position-relative overflow-hidden py-5 bg-white-gray">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  title="Related Blog "
                  subTitle="BLOGS &amp; NEWS"
                />
              </div>
            </div>
            <div className="blog-list-area position-relative mt-3 mb-2">
              <div className="row justify-content-center">
                <div className="col-lg-12">
                  <div className="blog-list-slider-area">
                    <Swiper
                      className="blog-list-slider equal-height"
                      spaceBetween={20}
                      slidesPerView={4}
                      // autoplay={{
                      //     delay: 2500,
                      //     disableOnInteraction: false,
                      // }}
                      modules={[Autoplay, Navigation]}
                      loop={true}
                      navigation={{
                        nextEl: '.blog-list-slider-area .swiper-button-next',
                        prevEl: '.blog-list-slider-area .swiper-button-prev',
                      }}
                      breakpoints={{
                        // when window width is >= 320px
                        320: {
                          slidesPerView: 1,
                          spaceBetween: 15,
                        },
                        // when window width is >= 480px
                        700: {
                          slidesPerView: 2,
                          spaceBetween: 20,
                        },
                        900: {
                          slidesPerView: 3,
                        },
                        1200: {
                          slidesPerView: 4,
                        },
                      }}
                    >
                      {relatedBlogs.map((blog, index) => {
                        return (
                          <SwiperSlide key={index}>
                            <BlogBox blog={blog} />
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                    <div className="swiper-buttons">
                      <div className="swiper-button-prev">
                        <FontAwesomeIcon icon={faChevronLeft} />
                      </div>
                      <div className="swiper-button-next">
                        <FontAwesomeIcon icon={faChevronRight} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RealatedBlogArea;

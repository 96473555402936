import Link from 'next/link';
import React from 'react';
import CustomImage from '../image/CustomImage';
import { apiUrl } from '../../../config/apiConfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';

const BlogBox = ({ blog }) => {

  const text = blog?.long_description;
  const wpm = 225;  //word per minutes
  const totalWords = text.trim().split(/\s+/).length;
  const readingtime = Math.ceil(totalWords / wpm) + " mins to read";

  return <>
    {blog &&
      <Link
        href="/[slug]"
        as={`/${blog.slug}`}
        className={`blog-box-area d-flex flex-column overflow-hidden h-100 radius-16 bg-white`}>

        <div className="blog-image position-relative overflow-hidden">
          <div className="blog-image-inner position-relative overflow-hidden w-100 h-100">
            <CustomImage
              src={blog.image ? apiUrl + blog.image : ""}
              layout="fill"
              objectFit="cover"
            />
          </div>
        </div>
        <div className="blog-content p-3 d-flex flex-column flex-grow-1">
          <div className="blog-content-inner flex-grow-1">
            <h3 className="text-black">{blog.blog_name}</h3>
            <p className="blog-content-text fs-17 lh-27 mb-2 text-justify">{blog.short_description.substring(0, 250)}</p>
          </div>
          <div className="d-flex justify-content-between align-items-center mt-1">
            <div className="blog-reading-time text-gray-purple fw-semibold fs-14 lh-22">{readingtime}</div>
            <div className="blog-more-link text-blue fs-14 lh-22 d-flex align-items-center">
              <span className="text">Read More</span>
              <span className="icon ms-1">
                <FontAwesomeIcon icon={faLongArrowAltRight} />
              </span>
            </div>
          </div>
        </div>

      </Link>
    }
  </>;
};




export default BlogBox;
// pages/404.js
import React from 'react';
import CustomHead from '../components/common/head/Head';
import NotFound from '../components/common/404/NotFound';

function Custom404({
  titleId = 'page_not_found',
  messageId = 'page_not_found',
  imageSrc = '/images/sad.png',
  linkUrl = '/',
  linkTextId = 'go_to_homepage',
  isLink = true,
  isLinkShow = true,
  isDownload = false,
  isFull = true,
  statusCode
}) {

  // const intl = useIntl();

  return (
    <div className={`${isFull ? 'header-fixed-content-layout' : ''}  py-5`}>
      <CustomHead
        title={"Page not found!"}
      />
      <div className={`${isFull ? 'container mh-60 error-page' : ''} d-flex py-5 text-center align-items-center justify-content-center`}>
        <NotFound
          message="We couldn&apos;t find the page you are looking for."
          link="/"
          linkText="Go to Homepage"
        />
      </div>
    </div>
  );
}

Custom404.getInitialProps = ({ res, err }) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404
  return { statusCode }
}

export default Custom404